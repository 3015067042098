
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_categories(params)  {
            return await axios.post('categories/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_categories()  {
            // return await axios.get('categories/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_categories')
        },
        async delete_categories(category_id)  {
            return await axios.post('categories/delete.php' , {
                category_id : category_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(category_id)  {
            return await axios.post('categories/readOne.php' , {
                category_id : category_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_categories(params)  {
            return await axios.post('categories/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_categories(params)  {
            return await axios.post('categories/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
